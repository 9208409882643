// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../IntroTeaser'

import messages from './404Page.lang'
import baseStyles from './404Page.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'

const IntroImage = (
  <StaticImage src="../../images/heroteaser/404_heroteaser_bg_desktop.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage src="../../images/heroteaser/404_heroteaser_bg_desktop.jpg" objectFit="cover" alt="" className="image" />
)

type Props = {
  ...StyleProps
}

const PartnerPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={HeroTeaserImage} alt="guy wondering" />
      <IntroTeaser
        title={formatMessage(messages.introTitle)}
        copy={<FormattedMessageSanitized tagName="p" {...messages.introInfo} />}
        image={IntroImage}
        btn={formatMessage(messages.btn)}
        btnLink="/"
        fullWidth
      />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(PartnerPage)
