import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Oops',
  introTitle: 'This is not the page you are looking for',
  introInfo: 'It\'s possible, that you\'ve followed a legacy link or that this page is currently under construction. Feel free to navigate the site and find what you are looking for. Have a great journey!',
  btn: 'Back to home'
})
