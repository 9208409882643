// @flow

import React, { type Node } from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Link } from 'gatsby-plugin-intl'
import { Container } from '../../components/basic/Grid'
import Button from '../../components/basic/Button'
import baseStyles from './SubpageHeroTeaser.scss'

type Props = {
  ...StyleProps,
  title?: Node,
  titleTag?: string,
  titleLink?: string,
  titleLinkButton?: boolean,
  titleLinkLabel?: string,
  pageImg?: Object,
  pageImgNote?: string | Node,
  aside?: any,
  imgAlt?: string,
  pageImgDescription?: string,
  image?: string | Node
}

const HeroTeaser = ({
  styles,
  title,
  titleTag,
  titleLink,
  titleLinkButton,
  titleLinkLabel,
  pageImg,
  pageImgNote,
  aside,
  pageImgDescription,
  image
}: Props) => {
  const TitleTag = titleTag || 'h1'
  return (
    <div
      className={classNames(
        styles.root,
        (pageImg || aside) && styles.secondary,
        titleLink && styles.isInner,
        titleLink && styles.hasBacklink
      )}
    >
      <div className={styles.imageWrap}>
        <div className={styles.imageContainer}>{image}</div>
      </div>
      <div className={classNames(styles.headlines, !titleLink && styles.isStatic)}>
        <Container styles={styles.container} fluid>
          <div className={styles.titleWrap}>
            {titleLink && titleLinkButton && (
              <div className={styles.backLinkWrapper}>
                <Link to={titleLink}>
                  <Button styles={styles.backLinkBtn} tag="span" color="link" icon="icon-arrow_03-left-medium">
                    {titleLinkLabel}
                  </Button>
                </Link>
              </div>
            )}
            <TitleTag className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            {titleLink && !titleLinkButton && (
              <Link to={titleLink} className={styles.backLink}>
                <span className={classNames(styles.backLinkIcon, 'icon-left')} />
                {titleLinkLabel}
              </Link>
            )}
          </div>
          {pageImg && (
            <div className={styles.pageImgWrapper}>
              <div className={classNames(styles.pageImgBox, Boolean(pageImgNote) && styles.hasNote)}>
                {pageImg}
                {pageImgNote && <span className={styles.pageImgNote}>{pageImgNote}</span>}
              </div>
              {pageImgDescription && <div className={styles.pageImgDescription}>{pageImgDescription}</div>}
            </div>
          )}
          {aside && <div className={styles.aside}>{aside}</div>}
        </Container>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(HeroTeaser)
